<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div v-if="isLoading" class="inquiry-wrapper">
        <PagePreloader/>
    </div>
    <div v-else>
        <div class="inquiry-wrapper " v-bind:class="{ 'open-filter-box': filterBoxActive}">
            <div class="add-property-title d-md-flex align-items-baseline me-auto">
                <div class=""> {{ $t('vendor-orders-title') }}</div>
              <div class="d-flex mt-2 ms-auto align-items-center">
                <div class="filter-by me-3 d-flex  align-items-center">
                  <div class="d-none ms-auto d-md-block"> {{ $t('filter-by-status') }}:</div>
                  <div class="col-md-6 col-12 ms-2">
                    <Dropdown v-model="selectedFilter" :selected-prop="selectedFilter" :label="$t('choose-status')" :options="orderStatuses"/>
                  </div>
                </div>
                <div class="ms-2 sort-filter d-flex justify-content-end">
                  <SortingDropdown :sorting-options="sortingOptions" :selected-value="orderBy" @sort="handleSorting"/>
                </div>
              </div>
            </div>

            <div class="mt-2" v-if="orders.length > 0">
                <div class=" labels-order d-none d-md-flex">
                    <div class="order-id col-1"> #</div>
                    <div class="client-name col-2">{{ $t('vendor-client-name') }}</div>
                    <div class="client-phone col-2">{{ $t('vendor-client-phone') }}</div>
                    <div class="order-status col-2 ">
                        {{ $t('vendor-order-status') }}
                    </div>
                    <div class="order-amount col-2"> {{ $t('vendor-order-amount') }}</div>
                    <div class="date col-2"> {{ $t('vendor-order-date') }}</div>
                </div>

                <div class="inquiry-item" v-for="order in orders">
                    <AdminVendorListItem
                        :id="order.id"
                        :date="order.createdAt"
                        :orderstatus="getOrderStatusNameById(order.status)"
                        :orderid="order.id"
                        :name="order.name"
                        :phone="order.phone"
                        :orderamount="order.totalSum"/>
                </div>
            </div>
            <div class="alert alert-warning" role="alert" v-else>
                {{ $t('there-are-no-orders-found') }}
            </div>
            <div class="pagination" v-if="totalOrdersCount !== null">
                <Pagination
                    :size="'small'"
                    :pages="Math.ceil(totalOrdersCount/limit)"
                    :route="'AdminPropertyList'"
                    :current="currentPage"
                    @pageChanged="handlePageChange"
                />
            </div>

        </div>
    </div>
</template>
