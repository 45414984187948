<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="admin-vendor-list-item-container d-md-none"
         @click="$router.push({name: 'AdminVendorSingleOrder', params: {id: orderid}})">
      <div class="inquiry-top-container d-flex ">
        <div class="property-id me-2"> {{ orderid }}</div>
        <div class="date me-auto"> {{ date }}</div>
        <div class="order-status order-status-printed d-flex align-items-center " v-if="orderstatus === 'printed'">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 13.1783H10.2143C10.7666 13.1783 11.2143 12.7306 11.2143 12.1783V5.99973L6.5 1.39258H2C1.44772 1.39258 1 1.84029 1 2.39258V12.1783C1 12.7306 1.44771 13.1783 2 13.1783Z"
                stroke="#919BA3"/>
          </svg>
          <div class="ms-1">Принтирано</div>
        </div>
        <div class="order-status order-status-printed d-flex align-items-center" v-if="orderstatus === 'done'">
          <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4L4.29289 7.29289C4.68342 7.68342 5.31658 7.68342 5.70711 7.29289L12 1" stroke="#1C1E2D"
                  stroke-width="2" stroke-linecap="round"/>
          </svg>
          <div class="ms-1">Приключена</div>
        </div>
      </div>
      <div class="inquiry-bottom-container">
        <div class="col-6 me-auto">
          <div class="client-name">{{ name }}</div>
          <div class="client-phone">{{ phone }}</div>
          <PreviewButton/>
        </div>
        <div class="order-amount"> {{ orderamount }} {{ $t('currency') }}</div>

      </div>
    </div>
    <div class="admin-vendor-list-item-container-desktop d-none d-md-flex align-items-center">
      <div class="order-id col-1">{{ orderid }}</div>
      <div class="client-name col-2">{{ name }}</div>
      <div class="client-phone col-2">{{ phone }}</div>
      <div class="order-status col-2 d-flex align-items-center">{{ orderstatus }}</div>
      <div class="order-amount col-2"> {{ orderamount }} {{ $t('currency') }}</div>
      <div class="date col-2"> {{ date }}</div>
      <PreviewButton @click="$router.push({name: 'AdminVendorSingleOrder', params: {id: orderid}})"/>
    </div>
  </div>
</template>
